<template>
  <div class="home-container">
    <div class="slide">
      <el-carousel trigger="click" arrow="never" height="600px">
        <el-carousel-item v-for="item in 1" :key="item">
          <div class="image">
            <img src="@/assets/yf/home-banner.png" class="home-banner">

          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content1" >
          <div style="width:70%;margin-left:15%;">
             <p style="wdith:100%;text-align:center;font-size:36px;line-height:40px;color:#333;margin:0">关于湖南银方</p>
             <p style="wdith:100%;text-align:center;font-size:14px;line-height:40px;color:#8C8C8C;margin:0">运用金融科技力量服务“三农”的高新技术企业</p>
             <div style="display: flex;">
                <div style="width:85%;display:inline-block;">
                    <p style="font-size:18px;line-height:40px;color:#4D4D4D;margin:0px">湖南银方信息技术有限公司</p>
                    <div v-html="content"></div>
                    <!-- <div style="font-size:14px;line-height:35px;color:#8C8C8C;margin:0px;padding-right:50px;text-indent:2em">
                        湖南银方信息技术有限公司成立于2019年7月，注册资本2000万元人民币，位于长沙高新开发区银河科技园内，由湖南新绿新集团与湖南惠银数据科技有限公司共同发起合资成立，是一家运用金融科技力量服务“三农”的高新技术企业。 湖南银方公司以深耕金融产业的服务能力为依托，携手全球知名的互联网企业，利用先进的AI技术、大数据风控技术，取得了浙江网商银行股份有限公司一级服务商资质，以及钉钉定制服务商、湖南销售部署服务商、湖南数字区县钉钉特许经营服务商资质，并在湖南省内开展了普惠金融服务、钉钉数字乡村部署服务、供应链金融服务、民办教育资金监管平台建设服务。 
                    </div>
                    <div style="font-size:14px;line-height:35px;color:#8C8C8C;margin:0px;padding-right:50px;text-indent:2em">
                        同时，湖南银方分别与长沙市国有资本投资运营集团有限公司、永州市开发建设投资有限公司合资组建了长沙国投银方征信有限公司......
                    </div> -->
                  <button class="read-more-button1"  type="text" @click="goto('/profile')">
                    <div>了解更多<svg-icon class="read-more el-icon--right" icon-class="read_more" /></div>
                  </button>
                </div>
                <div style="width:15%;display:inline-block;">
                    <img style="width:100%" src="@/assets/yf/daozi.png" />
                </div>
             </div>
          </div>
    </div>
      

    <!-- <div class="service" :style ="service">
      <div id="operation" class="block operation">
        <div style="display: flex;justify-content: center;align-items: center;">
        <div class="min-size">
          <div class="block-title">
            <div class="block-title">
              主营业务
              <el-divider class="title-direct" direction="vertical"></el-divider>
              <div class="title-right">稳健运营 砥砺前行</div>
            </div>
          </div>

          <div class="block-content">
            <img src="@/assets/yf/service-operation.png" style="width:100%" class="service-operation" alt="service-operation">
          </div>
        </div>
        </div>
      </div>
    </div> -->

    <div class="content1" style="background:#F5F5F5;">
        <div style="width:70%;margin-left:15%;">
          <p style="width:100%;text-align:center;font-size:36px;line-height:40px;color:#333;margin:0">主营业务</p>
          <p style="width:100%;text-align:center;font-size:14px;line-height:40px;color:#8C8C8C;margin:0">稳健运营 砥砺前行</p>
          <div style="width:20%;display:inline-block;position:relative">
           <img style="width:100%;" @click="goto('/service')" src="@/assets/yf/card1.png" />
            <span style="position:absolute;bottom:10%;color:#FFF;left:8%;font-size:16px;font-weight:600">普惠金融服务</span>
          </div>
          <div style="width:20%;display:inline-block;position:relative">
           <img style="width:100%;" @click="goto('/service')" src="@/assets/yf/card2.png" />
            <span style="position:absolute;bottom:10%;color:#FFF;left:8%;font-size:16px;font-weight:600">政企服务</span>
          </div>
          <div style="width:20%;display:inline-block;position:relative">
           <img style="width:100%;" @click="goto('/service')" src="@/assets/yf/card3.png" />
            <span style="position:absolute;bottom:10%;color:#FFF;left:8%;font-size:16px;font-weight:600">供应链金融</span>
          </div>
          <div style="width:20%;display:inline-block;position:relative">
           <img style="width:100%;" @click="goto('/service')" src="@/assets/yf/card4.png" />
            <span style="position:absolute;bottom:10%;color:#FFF;left:8%;font-size:16px;font-weight:600">金融服务乡村振兴落地运营</span>
          </div>
          <div style="width:20%;display:inline-block;position:relative">
           <img style="width:100%;" @click="goto('/service')" src="@/assets/yf/card5.png" />
            <span style="position:absolute;bottom:10%;color:#FFF;left:8%;font-size:16px;font-weight:600">大数据技术服务</span>
          </div>
         
        </div>
    </div>

    
    <div class="slide" style="height:400px">
      <el-carousel style="height:400px" trigger="click" arrow="never" height="400px">
        <el-carousel-item v-for="item in 1" :key="item" style="height:400px">
          <div class="image" style="height:400px">
            <img style="height:400px" src="@/assets/yf/home-banner1.png" class="home-banner">

            <div class="image-content" style="height:400px">
              <div class="min-size">
                <div class="article-block" >
                  <div class="title" style="text-align:center">县域普惠合作伙伴招募令</div>
                  <el-divider class="divider1" ></el-divider>
                  <div class="desc" style="width:100%;text-align:center;font-size:14px">数字普惠金融，助力乡村振兴。一起加入到县域普惠金融大团队，成为我们优秀的合作伙伴吧！</div>
                  <button class="read-more-button" style="margin-left:45%" type="text" @click="goto('/zhaomu')">
                    <div>查看更多<svg-icon class="read-more el-icon--right" icon-class="read_more" /></div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="partner" :style ="partner">
      <div class="min-size" >
        <p style="width:100%;text-align:center;font-size:36px;line-height:40px;color:#333;margin:0;margin-top:80px">合作伙伴</p>

        <div class="content" style="margin-top:50px">
          <div class="bank-item" v-for="(item, index) in bankList" :key="index">
            <img :src="item" alt="bank-item-image">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchNewsList,archivesList,archivesDetail } from '@/api/api'
const defaultSettings = require('@/settings.js')
export default {
  name: 'Home',
  data () {
    return {
      service: {
        backgroundImage: 'url(' + require('@/assets/yf/index-finace-bg.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center center'
      },
      news: {
        backgroundImage: 'url(' + require('@/assets/yf/index-news-bg.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center center'
      },
      partner: {
        backgroundImage: 'url(' + require('@/assets/yf/index-cooperation-bg.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center center'
      },
      newsList1: [
        {
          image: '/static/demo/news1.png',
          title: '国投银方与农业银行深度合作，助国投银方与农业银行深度合作，助',
          content: '更加深入地解决农村中低收入群体贷款难的问题，为更多中小微企业和农村个人创业者，提供“造血性”贷款。更加深入地解决农村中低收入群体贷款难的问题，为更多中小微企业和农村个人创业者，提供“造血性”贷款。',
          createAt: '2020-08-07 17:20:36'
        },
        {
          image: '/static/demo/news2.png',
          title: '助力农村中小微企业发展',
          content: '国投集团与湖南银方签署长沙市农村综合金融服务平台项目合作框架协议',
          createAt: '2020-08-07 17:20:36'
        },
        {
          image: '/static/demo/news3.png',
          title: '国投集团与湖南银方签署长沙市国投集团与湖南银方签署长沙市',
          content: '国投集团与湖南银方签署长沙市农村综合金融服务平台项目合作框架协议',
          createAt: '2020-08-07 17:20:36'
        }
      ],
      newsList2: [
        {
          image: '/static/demo/news3.png',
          title: '国投银方与农业银行深度合作，助国投银方与农业银行深度合作，助',
          content: '更加深入地解决农村中低收入群体贷款难的问题，为更多中小微企业和农村个人创业者，提供“造血性”贷款。更加深入地解决农村中低收入群体贷款难的问题，为更多中小微企业和农村个人创业者，提供“造血性”贷款。',
          createAt: '2020-08-07 17:20:36'
        },
        {
          image: '/static/demo/news2.png',
          title: '助力农村中小微企业发展',
          content: '国投集团与湖南银方签署长沙市农村综合金融服务平台项目合作框架协议',
          createAt: '2020-08-07 17:20:36'
        },
        {
          image: '/static/demo/news1.png',
          title: '国投集团与湖南银方签署长沙市国投集团与湖南银方签署长沙市',
          content: '国投集团与湖南银方签署长沙市农村综合金融服务平台项目合作框架协议',
          createAt: '2020-08-07 17:20:36'
        }
      ],
      bankList: [
        '/static/demo/bank3.png',
        '/static/demo/bank1.png',
        '/static/demo/bank2.png',
      ],
      companyid:'',
      mediaid:'',
      content:'',
    }
  },
  components: {
  },
  computed: {
    autoHeight () {
      const _w = document.documentElement.clientWidth || document.body.clientWidth
      return _w * 740 / 1920 + 'px'
    }
  },
  mounted () {
  },
  destroyed () {
  },
  created () {
    this.getList()
  },
  methods: {
    goto (path) {
      this.$router.push(path)
    },
    getList() {
      fetchNewsList().then(response => {
        response.data.map((r)=>{
          if(r.name == '湖南银方-首页'){
            r.children.map((m)=>{
              if(m.name=='首页-公司介绍'){
                archivesList({currentPage:1,currentSize:99,id:m.id}).then(response => {
                  let arr = []
                  arr = response.data.rows
                  archivesDetail({id:arr[0].id}).then(response => {
                    this.title = response.data.title
                    this.content = response.data.content
                    this.pushTime = response.data.pushTime
                  })
                })
              }
            })
          }
        })
      })
    },
  }
}
</script>

<style lang="less">
.home-container {
  .content1{
    width: 100%;
    padding: 80px 0px;
  }
  .slide {
    width: 100%;

    .image {
      width: 100%;
      height: 100%;
      background: linear-gradient(135deg, rgba(0,0,0,0.00), rgba(0,0,0,0.1));
    
      .home-banner {
        width: 100%;
        height: 100%;
        mix-blend-mode: overlay;
      }

      .image-content {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        text-align: center;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .min-size {
         min-width: 1200px;
          width: 70%;
          background: rgba(0,24,37,0.5);
        }

        .article-block {
          padding: 40px 0 30px 50px;
          color: #ffffff;
          display: flex;
          flex-direction: column;

          .title {
            text-align: left;
            font-size: 36px;
          }

          .divider {
            margin: 12px 0 20px 0;
            width: 90px;
            height: 3px;
          }

          .divider1 {
            margin: 12px 0 20px 0;
            width: 90px;
            height: 3px;
            margin-left: 45%;
          }

          .desc {
            text-align: left;
            width: 840px;
            font-size: 16px;
            line-height: 28px;
            padding-bottom: 50px;
          }
        }
      }
    }
  }

  .service {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    height: 903px;

    .min-size {
      width: 1200px;
      height: 703px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

       .block-title {
          display: flex;
          height: 104px;
          padding-bottom: 60px;

          .block-title {
            padding-left: 20px;
            display: flex;
            font-size: 30px;
            font-family: Microsoft YaHei, Microsoft YaHei-Bold;
            font-weight: 500;
            color: #ffffff;
            line-height: 40px;
            align-items: center;
          }
        }

      .title {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .row1 {
          font-size: 36px;
          line-height: 40px;
        }

        .row2 {
          font-size: 20px;
          line-height: 40px;
        }
      }

      .content {
        display: flex;
        height: 565px;
        box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.25);

        .col1 {
          width: 554px;
          height: 565px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            transition: all 0.3s;

            &:hover {
              transform: scale(1.1);
            }
          }
        }

        .col2 {
          .row-image {
            width: 328px;
            height: 282px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              transition: all 0.3s;

              &:hover {
                transform: scale(1.1);
              }
            }
          }

          .row-text {
            width: 100%;
            height: 283px;
            background: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;

            .image-desc {
              font-size: 16px;
              line-height: 40px;
              color: #000000;

              .image-desc-divider {
                margin: 0 0 30px 0;
                width: 50px;
                height: 3px;
                background: #c00000;
              }
            }
          }
        }

        .col3 {
          .row-text {
            width: 100%;
            height: 283px;
            background: #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;

            .image-desc {
              font-size: 16px;
              line-height: 40px;
              color: #000000;

              .image-desc-divider {
                margin: 0 0 30px 0;
                width: 50px;
                height: 3px;
                background: #c00000;
              }
            }
          }

          .row-image {
            width: 317px;
            height: 282px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              transition: all 0.3s;

              &:hover {
                transform: scale(1.1);
              }
            }
          }
        }
      }
    }
  }

  .news {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    height: 749px;

    .min-size {
      min-width: 1200px;
      width: 70%;
      height: 549px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .row1 {
          font-size: 36px;
          line-height: 40px;
        }

        .row2 {
          font-size: 20px;
          line-height: 40px;
        }
      }

      .content {
        display: flex;
        height: 412px;

        .news-tab {
          width: 100%;

          .el-tabs__header.is-left {
            margin-right: 80px;
          }

          .el-tabs__item {
            opacity: 0.6;
            font-size: 18px;
            font-weight: 400;
            color: #ffffff;
            padding: 0 10px 0 2px;
            line-height: 80px;
            height: 80px;

            &.is-active {
              opacity: 1;
              font-weight: 700;
            }
          }

          .el-tabs__active-bar {
            background-color: #c00000;
          }

          .news-list {
            display: flex;
            justify-content: space-between;

            .news-item {
              width: 310px;

              &:hover {
                img {
                  transform: scale(1.1);
                }

                .news-content {
                  .block {
                    .news-title {
                      color: #C00000;
                    }
                  }
                }
              }

              .image {
                width: 310px;
                height: 230px;
                overflow: hidden;

                img {
                  width: 100%;
                  height: 100%;
                  transition: all 0.3s;
                }
              }

              .news-content {
                width: 100%;
                height: 182px;
                background: #ffffff;
                box-shadow: 0px 5px 15px 0px rgba(255,255,255,0.25);
                display: flex;
                justify-content: center;
                align-items: center;

                .block {
                  width: 273px;
                  height: 150px;

                  .news-title {
                    color: #333333;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 40px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  }

                  .news-desc {
                    height: 54px;
                    padding-top: 4px;
                    color: #999999;
                    font-size: 14px;
                    line-height: 18px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                  }

                  .news-create-at {
                    padding-top: 32px;
                    color: #999999;
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .partner {
    display: flex;
    justify-content: center;
    align-items: center;

    .min-size {
      width: 70%;
      height: 392px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .content {
        height: 254px;
        display: flex;
        justify-content: space-around;
        align-content: space-between;
        flex-wrap: wrap;

        .bank-item {
          width: 280px;
          height: 114px;
          box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.15);
          margin-left: 20px ;

          &:hover {
            box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.25);
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .read-more-button {
    border: 1px solid #ffffff;
    border-radius: 0;
    width: 108px;
    height: 40px;
    color: #ffffff;
    font-size: 14px;
    background: transparent;
    cursor: pointer;

    .read-more {
      font-size: 16px;
    }

    &:hover {
      background: #df0000;
      border: none;
    }
  }

  .read-more-button1{
    border: 1px solid #ffffff;
    margin-top:40px;
    border-radius: 0;
    width: 108px;
    height: 40px;
    color: #ffffff;
    font-size: 14px;
    background: #df0000;
    cursor: pointer;

    .read-more {
      font-size: 16px;
    }

    &:hover {
      background: #df0000;
      border: none;
    }
  }
}
</style>
